<template>
  <div class="bg" style="cursor:pointer">
    <!-- 首页头部组件 -->
    <header_public></header_public>
    <div class="maska" v-if="videoState == true" @click="masksCloseFun()"></div>
    <div class="videomasks" v-if="videoState == true">
      <video
        :src="videoSrc"
        controls="controls"
        autoplay
        style="width: 57vw;height:32vw;"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div class="hello">
      <!-- 点击播放按钮 -->
      <!--            <div class="topbutton" @click="checkVideoFun(courselist[0] ? courselist[0]['image'] : '')" style="cursor:pointer"><p>点击播放</p><img src="../../assets/images/bofang.png" alt=""></div>-->
      <!-- <div class="giftbg"><img src="../../assets/images/gift.png" alt=""><button class="giftbutton">立即了解</button></div> -->
    </div>
    <div class="gift">
      <div class="giftbg">
        <div class="gift1">
          <a
            href="javascript:void(0)"
            class="btns"
            @click="advice"
            rel="nofollow"
            target="_blank"
          >
            <img src alt />
            <button class="giftbutton" style="cursor:pointer">立即了解</button>
          </a>
          <!-- 新课程学习大礼包 -->
          <div class="new1">
            <p class="new1p1">新课程学习大礼包</p>
            <p class="new1p2">(活动时间：即日起 &nbsp;—&nbsp;2021.05.31)</p>
          </div>
          <div class="new2">
            <p class="new2p2">报名可获赠学习大礼包</p>
            <p class="new2p1">海量线上线下资源随便拿</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程从4.0到5.0的蜕变 -->
    <div class="centerbg"></div>
    <div class="centerbg2" style="cursor:pointer">
      <!-- 专家说5.0课程 -->
      <div class="bofang">
        <div class="say">
          <img
            :src="videolistinfo[0] ? videolistinfo[0]['imageThumb'] : ''"
            alt
            class="sayimg1"
            @click="
              checkVideoFun(videolistinfo[0] ? videolistinfo[0]['image'] : '')
            "
          />
          <!-- <img class="bigbf" src="../../assets/images/bfbig_03.png" alt=""> -->
          <div class="sayvideo">
            <img
              :src="majorlist[4] ? majorlist[4]['imageThumb'] : ''"
              alt
              @click="checkVideoFun(majorlist[4] ? majorlist[4]['image'] : '')"
              class="sayimg2"
            />
            <img
              :src="majorlist[5] ? majorlist[5]['imageThumb'] : ''"
              alt
              @click="checkVideoFun(majorlist[5] ? majorlist[5]['image'] : '')"
              class="sayimg3"
            />
          </div>
        </div>
        <img class="bigbf" src="../../assets/images/bfbig_03.png" alt />
        <img class="bf1" src="../../assets/images/bofang.png" alt />
        <img class="bf2" src="../../assets/images/bofang.png" alt />
      </div>
      <div class="bofang1">
        <div class="sayvideo4">
          <ul>
            <li>
              <img
                :src="majorlist[3] ? majorlist[3]['imageThumb'] : ''"
                alt
                @click="
                  checkVideoFun(majorlist[3] ? majorlist[3]['image'] : '')
                "
              />
            </li>
            <li class="li1">{{ majorlist[3] ? majorlist[3]["title"] : "" }}</li>
            <li class="li2">
              {{ majorlist[3] ? majorlist[3]["extend"]["subtitle"] : "" }}
            </li>
          </ul>
          <ul>
            <li>
              <img
                :src="majorlist[2] ? majorlist[2]['imageThumb'] : ''"
                alt
                @click="
                  checkVideoFun(majorlist[2] ? majorlist[2]['image'] : '')
                "
              />
            </li>
            <li class="li1">{{ majorlist[2] ? majorlist[2]["title"] : "" }}</li>
            <li class="li2">
              {{ majorlist[2] ? majorlist[2]["extend"]["subtitle"] : "" }}
            </li>
          </ul>
          <ul>
            <li>
              <img
                :src="majorlist[0] ? majorlist[0]['imageThumb'] : ''"
                alt
                @click="
                  checkVideoFun(majorlist[0] ? majorlist[0]['image'] : '')
                "
              />
            </li>
            <li class="li1">{{ majorlist[0] ? majorlist[0]["title"] : "" }}</li>
            <li class="li2">
              {{ majorlist[0] ? majorlist[0]["extend"]["subtitle"] : "" }}
            </li>
          </ul>
          <ul>
            <li>
              <img
                :src="majorlist[1] ? majorlist[1]['imageThumb'] : ''"
                alt
                @click="
                  checkVideoFun(majorlist[1] ? majorlist[1]['image'] : '')
                "
              />
            </li>
            <li class="li1">{{ majorlist[1] ? majorlist[1]["title"] : "" }}</li>
            <li class="li2">
              {{ majorlist[1] ? majorlist[1]["extend"]["subtitle"] : "" }}
            </li>
          </ul>
        </div>
        <img class="bf3" src="../../assets/images/bofang.png" alt />
        <img class="bf4" src="../../assets/images/bofang.png" alt />
        <img class="bf5" src="../../assets/images/bofang.png" alt />
        <img class="bf6" src="../../assets/images/bofang.png" alt />
      </div>
    </div>

    <!-- 5.0课程升级亮点背景图 -->
    <div class="level"></div>
    <!-- 通往高薪的阶梯 -->
    <div class="gaoxin"></div>
    <!-- 美国CBE教学模式 -->
    <div class="america`"></div>
    <!-- 5.0系统解决方案 -->
    <div class="fangan"></div>
    <!-- 5.0名师配置 视频 -->
    <div class="teacher" style="cursor:pointer">
      <p class="teacherp">云集各专业大神大咖，线上线下坐镇5.0课程体系</p>
      <!-- 视频 -->
      <img
        class="video"
        :src="teacherlist[0] ? teacherlist[0]['imageThumb'] : ''"
        alt
        @click="checkVideoFun(teacherlist[0] ? teacherlist[0]['image'] : '')"
      />
      <img class="bigbf1" src="../../assets/images/bfbig_03.png" alt />
    </div>
    <!--申请试听-->
    <Apply_form></Apply_form>
    <!-- 底部 -->
    <bottom_public></bottom_public>
  </div>
</template>
<script>
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";
import Apply_form from "../../components/apply/apply_form";
const axios = require("axios").default;
export default {
  metaInfo: {
    title: "5.0课程体系-[积云教育]",
    meta: [
      {
        name: "description",
        content:
          "本栏目对积云教育5.0课程体系进行了简单的介绍，包括课程升级的亮点、名师的配置，需要的学员可以填写信息申请试听课程。",
      },
      {
        name: "keywords",
        content: "5.0课程体系",
      },
    ],
  },
  created() {
    this.getvideolistinfo(); //获取积云影音信息
    this.getmajorlist();
    this.getcourselist();
    this.getteacherinfo();
  },
  mounted() {
  },
  data() {
    return {
      videoSrc: "",
      videoState: false,
      videolist: {},
      videolistinfo: {},
      majorlist: {},
      courselist: {},
      teacherlist: {},
    };
  },
  methods: {
    advice(){
      window.mantis.requestChat()
    },
    //积云影音
    getvideolistinfo() {
      axios
        .get("/api/block/getItemsByName?name=" + encodeURIComponent("积云影音"))
        .then((res) => {
          this.videolistinfo = res.data.data; //积云影音列表数据
        });
    },
    getmajorlist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" + encodeURIComponent("专家说5.0")
        )
        .then((res) => {
          this.majorlist = res.data.data; //积云影音列表数据
        });
    },
    getcourselist() {
      axios
        .get("/api/block/getItemsByName?name=" + encodeURIComponent("课程5.0"))
        .then((res) => {
          this.courselist = res.data.data; //积云影音列表数据
        });
    },
    getteacherinfo() {
      axios
        .get(
          "/api/block/getItemsByName?name=" + encodeURIComponent("教师视频墙")
        )
        .then((res) => {
          this.teacherlist = res.data.data; //积云影音列表数据
        });
    },
    checkVideoFun(src) {
      this.videoState = true;
      this.videoSrc = src;
    },
    masksCloseFun() {
      this.videoState = false;
    },
  },
  components: {
    Apply_form,
    bottom_public,
    header_public,
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.hello {
  width: 100%;
  height: 56.82vw;
  background: url("~@/assets/images/top5.0.png") no-repeat fixed center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  background-attachment: scroll;
  /* 老版本的 Firefox */
  position: relative;

  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;

  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}
.gift {
  width: 100%;
  height: 39.8vw;
  background: url("~@/assets/images/top5.1.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
  margin-top: -0.2vw;
}
.gift1 {
  width: 79.895vw;
  height: 42.656vw;
  background: url("~@/assets/images/gift1.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
  top: -1.3vw;
  left: 10vw;
}
.topbutton {
  width: 17.4vw;
  height: 4.68vw;
  background: linear-gradient(to top right, #d71345, #2b4490, #33a3dc);
  border-radius: 80px;
  position: absolute;
  top: 42vw;
  left: 14.5vw;
  display: flex;
}
.topbutton p {
  width: 7.81vw;
  height: 1.875vw;
  font-size: 2em;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 4.2vw;
  margin-left: 3vw;
}
.topbutton img {
  width: 2.5vw;
  height: 2.5vw;
  margin-left: 1vw;
  margin-top: 1vw;
}
.giftbutton {
  width: 17.5vw;
  height: 4.7vw;
  background: linear-gradient(to top right, #ff0348, #f50963, #d11ac0, #cc1dd0);
  border-radius: 80px;
  color: #ffffff;
  font-size: 2.3em;
  position: absolute;
  top: 27vw;
  left: 11vw;
  border: none;
}
.new1 {
  width: 39.94;
  height: 8.07vw;
  position: absolute;
  top: 5vw;
  left: 19vw;
}
.new1p1 {
  font-size: 5rem;
  font-weight: 800;
  color: #fff;
}
.new1p2 {
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
}
.new2 {
  width: 30vw;
  height: 8.07vw;
  position: absolute;
  top: 19vw;
  left: 11vw;
  // background-color: red;
}
.new2p1 {
  font-size: 2.5rem;
  margin-top: 1vw;
  font-weight: 400;
  color: #fff;
  text-align: left;
}
.new2p2 {
  font-size: 3rem;
  font-weight: 800;
  color: #fff;
  text-align: left;
}
//课程从4.0到5.0的蜕变
.centerbg {
  width: 100%;
  height: 76.87vw;
  margin-top: -1vw;
  background: url("~@/assets/images/center1.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
.centerbg2 {
  width: 100%;
  height: 52.86vw;
  background: url("~@/assets/images/center2.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  margin-top: -1vw;
}
//专家说5.0课程
//大box
.say {
  width: 62%;
  padding-top: 10vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
//播放按钮
.bofang {
  width: 100%;
  position: relative;
}
.bigbf {
  width: 5.93vw;
  height: 5.93vw;
  position: absolute;
  top: 20vw;
  left: 40vw;
}
.bf1 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 10.5vw;
  right: 19.5vw;
}
.bf2 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 23.5vw;
  right: 19.5vw;
}
///////
.bofang1 {
  width: 100%;
  position: relative;
}
.bf3 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 5vw;
  left: 30vw;
}
.bf4 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 5.5vw;
  left: 46vw;
}
.bf5 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 5.4vw;
  left: 61.5vw;
}
.bf6 {
  width: 2.44vw;
  height: 2.44vw;
  position: absolute;
  top: 5.3vw;
  left: 77vw;
}
.say img {
  width: 100%;
  height: 49%;
}
.say .sayimg1 {
  width: 90%;
  margin-right: 0.5vw;
}
//小box2
.sayvideo {
  width: 33%;
  display: flex;
  flex-wrap: wrap;
}
.sayvideo .img {
  width: 100%;
  height: 49%;
}
.say .img2 {
  margin-bottom: 0.5vw;
}
.sayvideo4 {
  width: 62%;
  margin: 0 auto;
  margin-top: 0.5vw;
  display: flex;
  justify-content: space-between;
}
.sayvideo4 ul {
  width: 24%;
  height: 30%;
  // background-color: #fff;
}
.sayvideo4 img {
  width: 100%;
}
.sayvideo4 ul .li1 {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  text-align: left;
}
.sayvideo4 ul .li2 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(148, 148, 148, 1);
  text-align: left;
  margin-top: 0.5vw;
}
//5.0课程升级亮点
.level {
  width: 100%;
  height: 63.6vw;
  background: url("~@/assets/images/say_04.png") no-repeat center;
  margin-top: -1vw;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
//通往高薪的阶梯
.gaoxin {
  width: 100%;
  height: 65.78vw;
  background: url("~@/assets/images/jieti.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
//美国cbe教学模式
.america {
  width: 100%;
  height: 42.96vw;
  background: url("~@/assets/images/am.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  // margin-top: -9.35vw;
}
//5.0系统解决方案
.fangan {
  width: 100%;
  height: 56.45;
  background: url("~@/assets/images/fl.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
}
//5.0名师配置
.teacher {
  width: 100%;
  height: 67vw;
  background: url("~@/assets/images/dibu.png") no-repeat center;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: relative;
}
.bigbf1 {
  width: 5.93vw;
  height: 5.93vw;
  position: absolute;
  top: 37vw;
  left: 47vw;
}
.teacherp {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  position: absolute;
  top: 15vw;
  left: 37vw;
}
.teacher .video {
  width: 58%;
  margin: 0 auto;
  margin-top: 23vw;
}
.foot_box {
  margin-top: 0 !important;
}
.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}
.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}
.videomasks video {
  width: 100%;
  height: 100%;
}
</style>
